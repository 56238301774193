.header {
    /*position: fixed;*/
    border-radius: 100px;
    background: rgba(255, 255, 255, 0.10);
    backdrop-filter: blur(19px);
    z-index: 10;
}

.thisButton {
    background:  #EEC139;
    color: #151519;
}