.container {
    border-radius: 15px;
    background: #27283B;
}

.icon {

    width: 12%;

}

.thisButton {
    border-radius: 5px;
    border: 1px solid  #EAEAEA;
    background:  #27283B;
    color: #EAEAEA;
    width: 25%;
}