.container {

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.img {
    width: 90%;
}

.icon {
    width: 13%;
}

.thisButton {
    background:  #EEC139;
    color: #151519;
}
