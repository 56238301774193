@media (min-width: 992px) {

    html, body {
        font-size: 0.82vw;
        line-height: 4vh;
        height: 100%;

    }

    .button {
        height: 5vh;
        font-size: 0.8vw;
    }

}

@media (min-device-width: 480px) and (max-device-width: 1360px) {

    html, body {
        font-size: 1.55vw;
        line-height: 3vh;

    }

    .button {
        height: 4.6vh;
        font-size: 1.55vw;
    }


}


@media (max-width: 480px) {

    html, body {
        font-size: 2.9vw;
        line-height: 3.6vh;

    }

    .button {
        height: 5vh;
    }
}