/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.container {
    /* height: 85vh;*/
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.card {
    position: relative;
    height: 42vh;
    border-radius: 30px;
    border: 1px solid transparent;
    background: rgba(39, 40, 59, 0.40);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    background-clip: padding-box;
}

.card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 30px;
    border: 1px solid transparent;
    background: -webkit-gradient(linear, left top, left bottom, from(#6389FF), to(#EEC139)) border-box;
    background: -o-linear-gradient(top, #6389FF, #EEC139) border-box;
    background: linear-gradient(180deg, #6389FF, #EEC139) border-box;
    -webkit-mask:
            -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) padding-box,
            -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
    -webkit-mask:
            linear-gradient(#fff 0 0) padding-box,
            linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
}

.icon {
    width: 18%;
}

.thisButton {
    background:  #EEC139;
    color: #151519;
}