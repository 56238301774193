.container {

    top: 0;
    bottom: 0;
    position: absolute;

    /*background-color: #d73e36;*/
    /* top: 0;
     bottom: 0;
     width: 100%;
     position: absolute;*/


}

.content {

}