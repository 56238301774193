.container {
    height: 50vh;
}

.content {
    width: 20%;
    position: relative;
}

.card {
    height: 12vh;
    width: 15vw;
    text-align: center;
    border-radius: 15px;
    background-color: #27283B;
    top: 83%;
    z-index: 1;
}

.line {
    width: 11vw;
}