.container {
    z-index: 10;
    /*margin-top: 30vh;*/
    /*height: 100vh;*/
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.img {
    width: 60%;
}

.mainImg {
    height: 55vh;
    border-radius: 16.143px;
    background: #27283B;
}
.leftSideImg {
    width: 30%;
    height: 15vh;
    bottom: 10%;
    left: -7%;
    border-radius: 16.143px;
    background: #27283B;
    box-shadow: 0px 4px 38px 0px rgba(0, 0, 0, 0.45);
}
.rightSideImg {
    width: 25%;
    height: 40vh;
    bottom: 14%;
    right: -7%;
    border-radius: 16.143px;
    background: #27283B;
    box-shadow: 0px 4px 38px 0px rgba(0, 0, 0, 0.45);
}